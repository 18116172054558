<template>
  <div class="patient-form">
    <div class="breadcrumbs" v-if="this.$route.path.includes('/pacientes/novo')">
      <router-link 
        v-if="userHasPermission('FpPac1')"
        to="/pacientes" 
        tag="span" 
        class="link"
      >
        Meus pacientes
      </router-link>
      <span v-else>Meus pacientes</span>

      <ChevronRight class="chevron" />
      <span>
        <span>Novo paciente</span>
      </span>
    </div>

    <validation-observer ref="patientForm" class="patientForm">
      <div class="container">
        <p
          class="main-title"
          v-if="this.$route.path.includes('/pacientes/novo')"
        >
          Cadastro de novo paciente
        </p>
        <div class="d-flex align-items-center main-title mb-4" v-else>
          <div>
            <p>Edição de paciente</p>
            <p class="inactive-label" v-if="patient.delete_reason">
              (Paciente Inativo)
            </p>
          </div>
        </div>

        <b-col class="d-flex mb-20 pl-0">
          <div class="avatar">
            <img
              v-if="
                patient.picture != null &&
                patient.picture != '' &&
                image.cropedImage === ''
              "
              :src="`${patient.picture}?x=${moment()}`"
              width="100"
              height="100"
              alt="Paciente"
            />
            <img
              v-if="image.cropedImage != ''"
              :src="image.cropedImage"
              width="100"
              height="100"
              alt="Paciente"
            />
            <Avatar
              v-if="
                (patient.picture === null || patient.picture === '') &&
                image.cropedImage === ''
              "
              class="avatar"
            />

            <div
              class="camera-button"
              :class="{ disabled: inactivePatient }"
              :disabled="inactivePatient"
              @click="showPictureModal"
            >
              <Camera class="camera" />
            </div>
            <input
              ref="FileInput"
              type="file"
              style="display: none"
              accept="image/jpeg, image/png, image/tiff"
              @change="image.selectedFile = $event.target.files[0]"
            />
          </div>
        </b-col>
        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <label for="name">Nome completo</label>
              <validation-provider mode="lazy" name="name" rules="required">
                <input
                  autocomplete="off"
                  id="name"
                  v-model="patient.name"
                  type="text"
                  class="form-control"
                  @input="validateName('name')"
                  :class="{ error: validatedN && !patient.name }"
                />
                <div
                  v-if="validatedN && !patient.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="nickname"
                >Nome social <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="nickname"
                type="text"
                v-model="patient.nickname"
                placeholder="Descrever"
                class="form-control"
                @input="validateName('nickname')"
              />
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="cpf">CPF <span class="help">(opcional)</span></label>
              <validation-provider
                mode="lazy"
                name="CPF"
                rules="cpf"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="cpf"
                  type="text"
                  v-model="patient.cpf"
                  class="form-control"
                  placeholder="000.000.000-00"
                  v-mask="'###.###.###-##'"
                  @change="validateCPF(patient.cpf)"
                  :class="{
                    error:
                      (touched && errors.length > 0) ||
                      (existCpf && existCpf.id !== patient.id)
                  }"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
                <span
                  class="error-message"
                  v-if="existCpf && existCpf.id !== patient.id"
                  >CPF já cadastrado. Prontuário
                  {{ existCpf.medical_record_number }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="rg">RG <span class="help">(opcional)</span></label>
              <input
                autocomplete="off"
                id="rg"
                type="text"
                v-model="patient.rg"
                class="form-control"
                @input="checkAlphaNumeric($event)"
                @keydown="checkKeyDownAlphaNumeric($event)"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <div class="form-group">
              <label for="nome_mae" class="inline-text"
                >Nome do responsável <span class="help">(opcional)</span></label
              >
              <validation-provider
                mode="lazy"
                name="nome_mae"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="name_responsible"
                  type="text"
                  v-model="patient.name_responsible"
                  class="form-control"
                  placeholder="Descrever"
                  :class="{ error: touched && errors.length > 0 }"
                  @input="validateName('name_responsible')"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="telephone_responsible"
                >Telefone do Responsável <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="telephone_responsible"
                type="text"
                v-model="patient.telephone_responsible"
                class="form-control"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) ####-####']"
              />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="nome_mae" class="inline-text"
                >Nome da mãe <span class="help">(opcional)</span></label
              >
              <validation-provider
                mode="lazy"
                name="nome_mae"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="nome_mae"
                  type="text"
                  v-model="patient.mother_name"
                  class="form-control"
                  placeholder="Descrever"
                  :class="{ error: touched && errors.length > 0 }"
                  @input="validateName('mother_name')"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="telephone_mother"
                >Telefone da Mãe <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="telephone_mother"
                type="text"
                v-model="patient.telephone_mother"
                class="form-control"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) ####-####']"
              />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="nome_mae" class="inline-text"
                >Nome do pai
                <span class="help">(opcional)</span>
              </label>
              <validation-provider
                mode="lazy"
                name="nome_pai"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="nome_pai"
                  type="text"
                  v-model="patient.father_name"
                  class="form-control"
                  placeholder="Descrever"
                  :class="{ error: touched && errors.length > 0 }"
                  @input="ValidateName('father_name')"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="cns" class="inline-text"
                >Cartão Nacional de Saúde (CNS)
                <span class="help">(opcional)</span></label
              >
              <validation-provider
                mode="lazy"
                name="cns"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="cns"
                  type="text"
                  v-model="patient.cns"
                  class="form-control"
                  placeholder="000000000000000"
                  :class="{ error: touched && errors.length > 0 }"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group" id="prontuario-container">
              <label for="prontuario" class="inline-text"
                >Número do prontuário
                <span class="help">(opcional)</span>
              </label>
              <validation-provider
                mode="lazy"
                name="prontuario"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="prontuario"
                  type="text"
                  v-model="patient.medical_record_number"
                  class="form-control"
                  placeholder="000000000000000"
                  @keyup="debounceSearchMedicalRecord"
                  :class="{ error: touched && errors.length > 0 }"
                  :disabled="!patient.id"
                />
                <span class="error-message">
                  <p v-if="!validMedicalRecord">
                    Número de prontuário já existente.
                  </p>
                  <p
                    v-if="
                      !validMedicalRecord &&
                      nextMedicalRecordNumber &&
                      nextMedicalRecordNumber !== patient.medical_record_number
                    "
                  >
                    Próximo prontuário: {{ nextMedicalRecordNumber }}
                  </p>
                </span>
              </validation-provider>
              <b-tooltip
                target="prontuario-container"
                v-if="!patient.id"
                placement="bottom"
              >
                O número de prontuário será gerado automaticamente</b-tooltip
              >
            </div>
          </b-col>
          <b-col cols="12">
            <div class="form-group" id="mais-informacoes-container">
              <label for="mais-informacoes" class="inline-text">
                Mais informações <span class="help">(opcional)</span>
              </label>
              <TextArea
                id="mais-informacoes"
                :value="patient.info"
                @inputTextArea="
                  el => {
                    patient.info = el
                  }
                "
                placeholder="Descrever"
              />
            </div>
          </b-col>
        </b-row>
        <hr />

        <p class="sub-title">Informações pessoais</p>

        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <label for="job"
                >Profissão <span class="help">(opcional)</span></label
              >
              <validation-provider
                mode="lazy"
                name="profissão"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="job"
                  type="text"
                  v-model="patient.job"
                  placeholder="Descrever"
                  class="form-control"
                  :class="{ error: touched && errors.length > 0 }"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="birthday" class="inline-text">
                Data de nascimento
                <span class="help">(opcional)</span>
              </label>
              <input
                autocomplete="off"
                id="birthday"
                type="text"
                v-model="patient.birthday"
                class="form-control patient-date-input"
                @blur="bornDate()"
                placeholder="00/00/0000"
                v-mask="['##/##/####']"
              />
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="gender"
                >Sexo <span class="help">(opcional)</span></label
              >
              <multiselect
                id="gender"
                v-model="patient.gender"
                :options="genderOptions"
                track-by="value"
                label="name"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: touched && errors.length > 0 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="gender"
                >Raça <span class="help">(opcional)</span></label
              >
              <multiselect
                id="state"
                v-model="patient.ethnicity"
                :options="ethnicityOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: touched && errors.length > 0 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="gender"
                >Etnia <span class="help">(opcional)</span></label
              >
              <multiselect
                id="state"
                v-model="patient.ancestry"
                :options="ancestryOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: touched && errors.length > 0 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <label for="state"
                >Estado civil <span class="help">(opcional)</span></label
              >
              <multiselect
                id="state"
                v-model="patient.marital_status"
                :options="maritalStatusOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: touched && errors.length > 0 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="nationality"
                >Nacionalidade <span class="help">(opcional)</span></label
              >
              <validation-provider mode="lazy" name="nacionalidade">
                <multiselect
                  id="nationality"
                  autocomplete="off"
                  v-model="patient.nationality"
                  :options="nationalities"
                  label="value"
                  :option-height="40"
                  :showLabels="true"
                  :searchable="true"
                  placeholder="Selecione um país"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>

                  <template slot="noOptions"> Nenhuma opção </template>

                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="religion"
                >Religião <span class="help">(opcional)</span></label
              >
              <multiselect
                v-model="patient.religion"
                id="religion"
                :options="opcoesReligion"
                :option-height="40"
                :showLabels="false"
                :showNoResults="true"
                placeholder="Selecionar"
                class="with-border"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="setReligion(props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar "{{ props.search }}"
                    </div>
                  </li>
                </template>
              </multiselect>
            </div>
          </b-col>
        </b-row>

        <hr />

        <p class="sub-title">Contato</p>

        <b-row>
          <b-col cols="3">
            <div class="form-group">
              <label for="email"
                >E-mail <span class="help">(opcional)</span></label
              >
              <validation-provider
                mode="lazy"
                name="e-mail"
                v-slot="{ touched, errors }"
              >
                <input
                  autocomplete="off"
                  id="email"
                  type="email"
                  v-model="patient.email"
                  class="form-control"
                  placeholder="E-mail do paciente"
                  :class="{
                    error: (touched && errors.length > 0) || !validateEmail
                  }"
                />
                <span
                  class="error-message"
                  v-if="touched && errors.length > 0"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="telephone"
                >Telefone <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="telephone"
                type="text"
                v-model="patient.telephone"
                class="form-control"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) ####-####']"
              />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="cellphone"
                >Celular <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="cellphone"
                type="text"
                v-model="patient.cellphone"
                class="form-control"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="cellphone2"
                >Celular 2 <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="cellphone2"
                type="text"
                v-model="patient.cellphone2"
                class="form-control"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </div>
          </b-col>
        </b-row>

        <hr />

        <p class="sub-title">Endereço</p>

        <b-row>
          <b-col cols="2">
            <div class="form-group">
              <label for="zipcode">CEP</label>
              <input
                autocomplete="off"
                id="zipcode"
                type="text"
                v-model="patient.address.zipCode"
                class="form-control"
                v-mask="'#####-###'"
                @keyup="searchCEP"
                :class="{
                  error:
                    validatedA && !patient.address.zipCode && isInvalidAddress()
                }"
              />
              <div
                v-if="
                  validatedA && !patient.address.zipCode && isInvalidAddress()
                "
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="address">Logradouro</label>
              <input
                autocomplete="off"
                id="address"
                type="text"
                v-model="patient.address.address"
                class="form-control"
                :class="{
                  error:
                    validatedA && !patient.address.address && isInvalidAddress()
                }"
              />
              <div
                v-if="
                  validatedA && !patient.address.address && isInvalidAddress()
                "
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="number">Nº</label>
              <input
                autocomplete="off"
                id="number"
                ref="addressNumber"
                type="text"
                v-model="patient.address.number"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label for="complement"
                >Complemento <span class="help">(opcional)</span></label
              >
              <input
                autocomplete="off"
                id="complement"
                type="text"
                v-model="patient.address.complement"
                class="form-control"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="5">
            <div class="form-group">
              <label for="neighborhood">Bairro</label>
              <input
                autocomplete="off"
                id="neighborhood"
                type="text"
                v-model="patient.address.neighborhood"
                class="form-control"
                :class="{
                  error:
                    validatedA &&
                    !patient.address.neighborhood &&
                    isInvalidAddress()
                }"
              />
              <div
                v-if="
                  validatedA &&
                  !patient.address.neighborhood &&
                  isInvalidAddress()
                "
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </div>
          </b-col>
          <b-col cols="5">
            <div class="form-group">
              <label for="city">Cidade</label>
              <input
                autocomplete="off"
                id="city"
                type="text"
                v-model="patient.address.city"
                class="form-control"
                :class="{
                  error:
                    validatedA && !patient.address.city && isInvalidAddress()
                }"
              />
              <div
                v-if="validatedA && !patient.address.city && isInvalidAddress()"
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group">
              <label for="addressState">Estado</label>
              <multiselect
                id="addressState"
                v-model="patient.address.state"
                :options="statesOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                :allowEmpty="false"
                placeholder="UF"
                class="with-border"
                :class="{
                  error:
                    validatedA && !patient.address.state && isInvalidAddress()
                }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <div
                v-if="
                  validatedA && !patient.address.state && isInvalidAddress()
                "
                class="custom-invalid-feedback"
              >
                Campo obrigatório
              </div>
            </div>
          </b-col>
        </b-row>

        <hr />

        <div class="d-flex justify-content-between">
          <p class="sub-title">Convênio</p>
          <b-button
            variant="primary"
            class="btn-h40"
            @click="createHealthPlan"
            :disabled="inactivePatient"
          >
            Novo convênio
          </b-button>
        </div>

        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th>Convênio</th>
                  <th>Plano</th>
                  <th>Matrícula</th>
                  <th>Token</th>
                  <th>Validade</th>
                  <th>Titular</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    personHealthPlan, index
                  ) of patient.person_health_plans"
                  :key="index"
                >
                  <td>
                    <Ellipsis>{{
                      personHealthPlan.health_plan.fantasy_name
                    }}</Ellipsis>
                  </td>
                  <td>
                    <Ellipsis>{{
                      personHealthPlan.plan ? personHealthPlan.plan.name : '-'
                    }}</Ellipsis>
                  </td>
                  <td>
                    <Ellipsis>{{
                      personHealthPlan.registration || '-'
                    }}</Ellipsis>
                  </td>
                  <td>
                    <Ellipsis>{{ personHealthPlan.token || '-' }}</Ellipsis>
                  </td>
                  <td>
                    {{
                      personHealthPlan.valid_until
                        ? moment(personHealthPlan.valid_until).format(
                            'DD/MM/YYYY'
                          )
                        : '-'
                    }}
                  </td>
                  <td>{{ personHealthPlan.holder || '-' }}</td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          :disabled="inactivePatient"
                          @click="editHealthPlan(personHealthPlan)"
                          >Editar</b-button
                        >
                        <b-button
                          variant="link remove mb-0"
                          :disabled="inactivePatient"
                          @click="removeHealthPlan(index)"
                          >Remover</b-button
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

        <div class="mt-4 space-between">
          <div>
            <b-button
              v-if="!inactivePatient"
              v-can="'FpPac4'"
              variant="link"
              @click="$bvModal.show('confirm-patient-inactivation')"
            >
              Inativar Paciente
            </b-button>
            <b-button
              v-else-if="inactivePatient"
              v-can="'FpPac4'"
              variant="link"
              class="btn-h56"
              @click="$bvModal.show('confirm-patient-reactivation')"
            >
              Ativar Paciente
            </b-button>
          </div>
          <div>
            <b-button
              v-if="patient?.id"
              v-can="'FpPac4'"
              variant="link"
              class="delete-patient btn-h56"
              @click="$bvModal.show('confirm-patient-deletion')"
            >
              Excluir Paciente
            </b-button>
            <b-button
              v-if="!patient.id"
              v-can="'FpPac2'"
              variant="success"
              :disabled="
                existCpf || !validMedicalRecord || !this.validateBornDate
              "
              @click="savePatient"
            >
              Salvar paciente
            </b-button>
            <b-button
              v-else
              v-can="'FpPac3'"
              variant="success"
              class="btn-h56"
              :disabled="
                existCpf ||
                !validMedicalRecord ||
                !this.validateBornDate ||
                inactivePatient
              "
              @click="savePatient"
            >
              Atualizar paciente
            </b-button>
          </div>
        </div>
      </div>
    </validation-observer>

    <PatientHealthPlanModal
      :personHealthPlan="personHealthPlan"
      :addPersonHealthPlan="addPersonHealthPlan"
      :editIndex="editIndex"
      :editPersonHealthPlan="editPersonHealthPlan"
    />

    <ConfirmPatientDeletion :confirmedDelete="confirmedDelete" />

    <ConfirmPatientInactivation
      :confirmedInactivation="confirmedInactivation"
    />

    <ConfirmPatientReactivation
      :confirmedReactivation="confirmedReactivation"
    />

    <ProfilePictureModal
      :personId="patient?.id"
      @change-croped-image="changeCropedImage"
      @update-image="updateImage"
    />
  </div>
</template>
<script>
import * as cep from 'cep-promise'
import { debounce } from 'lodash'
import moment from 'moment'
import { cpf } from 'cpf-cnpj-validator'
import Ellipsis from '../../components/General/Ellipsis.vue'
import { userHasPermission } from '../../utils/localStorageManager'

export default {
  name: 'PatientForm',
  metaInfo: {
    title: 'Eyecare - Formulário de Paciente'
  },
  components: {
    Ellipsis,
    Avatar: () => import('@/assets/icons/avatar.svg'),
    Camera: () => import('@/assets/icons/camera.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    ConfirmPatientDeletion: () =>
      import('@/components/Patient/ConfirmPatientDeletion'),
    ConfirmPatientInactivation: () =>
      import('@/components/Patient/ConfirmPatientInactivation'),
    ConfirmPatientReactivation: () =>
      import('@/components/Patient/ConfirmPatientReactivation'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    PatientHealthPlanModal: () =>
      import('@/views/Patients/PatientHealthPlanModal'),
    ProfilePictureModal: () =>
      import('@/components/Patient/ProfilePictureModal'),
    Plus: () => import('@/assets/icons/plus.svg'),
    TextArea: () => import('@/components/General/TextArea')
  },
  created() {
    this.debounceSearchMedicalRecord = debounce(this.searchMedicalRecord, 500)
  },
  watch: {
    validMedicalRecord: function (newValue) {
      this.validMedicalRecord = newValue
    }
  },
  async mounted() {
    if (!this.$route.path.includes('/pacientes/novo')) {
      await this.getPatient()
      if (!this.patient.medical_record_number) this.getLastMedicalRecordNumber()
    }

    if (this.nationalities.length === 0) {
      this.api.getNationalities().then(async res => {
        this.nationalities = res.data
      })
    }
  },
  data() {
    return {
      touched: false,
      existCpf: false,
      validMedicalRecord: true,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      peopleImageUrl: process.env.VUE_APP_API_URL + 'storage/images/people/',
      nextMedicalRecordNumber: '',
      isLoading: false,
      validateBornDate: true,
      nationalities: [],
      validatedA: false,
      validatedN: false,
      patient: {
        id: null,
        picture: '',
        name: '',
        nickname: '',
        cpf: '',
        rg: '',
        job: '',
        birthday: '',
        gender: '',
        marital_status: '',
        nationality: '',
        email: '',
        telephone: '',
        cellphone: '',
        cellphone2: '',
        address: this.getDefaultAddress(),
        person_health_plans: [],
        info: null,
        religion: '',
        ethnicity: '',
      },

      personHealthPlan: null,
      editingMedicalInsurance: null,

      maritalStatusOptions: [
        'Casado(a)',
        'Divorciado(a)',
        'Separado(a)',
        'Solteiro(a)',
        'Viúvo(a)'
      ],
      statesOptions: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
      ],
      genderOptions: [
        { value: 'M', name: 'Masculino' },
        { value: 'F', name: 'Feminino' }
      ],
      ethnicityOptions: [
        'Branca',
        'Preta',
        'Parda',
        'Amarela',
        'Indígena',
      ],
      ancestryOptions:[
        'Africana',
        'Asiática',
        'Latina',
        'Indígena',
        'Árabe',
        'Eslava',
        'Judaica',
        'Romani'
      ],
      image: {
        selectedFile: '',
        cropedImage: '',
        cropedBlob: ''
      },
      editIndex: null,
      opcoesReligion: [
        'Católico',
        'Evangélico',
        'Não declarado',
        'Sem religião'
      ]
    }
  },
  computed: {
    inactivePatient() {
      return this.patient?.delete_reason
    }
  },
  methods: {
    userHasPermission,
    showPictureModal() {
      if (!this.inactivePatient) {
        this.$bvModal.show('profile-picture-modal')
      }
    },
    validateName(target) {
      /*
      Match a single character present in the list below [\p{L}\p{M}\s]
      * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
      \p{L} matches any kind of letter from any language
      \p{M} matches a character intended to be combined with another character (e.g. accents, umlauts, enclosing boxes, etc.)
      \s matches any kind of invisible character (equivalent to [\p{Z}\h\v])
      Global pattern flags 
      g modifier: global. All matches (don't return after first match)
      u modifier: unicode. Pattern strings are treated as UTF-16. Also causes escape sequences to match unicode characters
      */
      const regex = /^[\p{L}\p{M}\s]*$/gu
      if (!regex.test(this.patient[target])) {
        this.$toast.warning('Não é possível inserir caracter especial')
        this.patient[target] = this.patient[target].replace(
          /[^\p{L}\p{M}\s]/gu,
          ''
        )
      }
    },
    bornDate() {
      this.validateBornDate = true
      const dateFormat = 'DD/MM/YYYY'
      const strictMode = true

      const validDate = moment(
        this.patient.birthday,
        dateFormat,
        strictMode
      ).isValid()
      const convertedDate = moment(this.patient.birthday, dateFormat)
      const today = moment()
      const differenceInYears = today.diff(convertedDate, 'years')

      if (moment(convertedDate).isAfter(today)) {
        this.validateBornDate = false
        this.$toast.warning(
          'A data de nascimento não pode ser superior a data atual!'
        )
      }
      if (!validDate && this.patient.birthday !== '') {
        this.validateBornDate = false
        this.$toast.warning('Data de nascimento inválida!')
      }
      const ageOfOldestPersonInHistory = 122
      const minimunYear = this.calculateMinimumBornDateYear(
        today,
        ageOfOldestPersonInHistory
      )
      if (differenceInYears > ageOfOldestPersonInHistory) {
        this.validateBornDate = false
        this.$toast.warning(
          'Digite o ano de nascimento superior a ' + minimunYear
        )
      }
    },
    calculateMinimumBornDateYear(data, yearsToRemove) {
      const convertedDate = moment(data, 'DD/MM/YYYY')
      const newDate = convertedDate.subtract(yearsToRemove, 'years')
      const year = newDate.format('YYYY')
      return year
    },
    confirmedDelete(confirmed) {
      this.$bvModal.hide('confirm-patient-deletion')
      if (!confirmed) return

      const isLoading = this.$loading.show()
      this.api
        .deletePerson(this.patient.id)
        .then(response => {
          this.$toast.success('Paciente excluído com sucesso!')
          this.$router.push('/pacientes')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    confirmedInactivation(confirmed) {
      this.$bvModal.hide('confirm-patient-inactivation')
      if (!confirmed) return
      const data = { reason: null }
      const isLoading = this.$loading.show()
      this.api
        .inactivatePatient(this.patient.id, data)
        .then(response => {
          this.$toast.success('Paciente inativado com sucesso!')
          this.$router.push('/pacientes')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },

    confirmedReactivation(confirmed) {
      this.$bvModal.hide('confirm-patient-reactivation')
      if (!confirmed) return

      const isLoading = this.$loading.show()
      this.api
        .reactivatePatient(this.patient.id)
        .then(response => {
          this.$toast.success('Paciente reativado com sucesso!')
          this.$router.push('/pacientes')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    getDefaultAddress() {
      return {
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: ''
      }
    },
    async getPatient() {
      const isLoading = this.$loading.show()
      try {
        const withInactives = true
        const res = await this.api.getCompletePatient(
          this.$route.params.id,
          withInactives
        )
        this.patient = {
          ...res.data,
          address: res.data.address || this.getDefaultAddress(),
          birthday: res.data.birthday
            ? moment(res.data.birthday).format('DD/MM/YYYY')
            : null,
          nationality: {
            value: res.data.nationality ? res.data.nationality : 'Selecionar'
          },
          gender: this.genderOptions.find(
            gender => gender.value === res.data.gender
          )
        }

        if (this.patient.address.zipCode === '00000-000') {
          this.patient.address.zipCode = ''
        }

        isLoading.hide()
      } catch (ex) {
        isLoading.hide()
        this.$toast.error(ex.message)
      }
    },
    validateCPF(cpf) {
      this.api.validateCpf(this.clinic.id, cpf).then(res => {
        this.existCpf = cpf.length && res.data
      })
    },
    async searchMedicalRecord() {
      if (
        this.patient.medical_record_number === '' ||
        this.patient.medical_record_number === null
      ) {
        this.validMedicalRecord = true
        return
      }

      try {
        const { data: patientFound } = await this.api.findPatientBy(
          this.clinic.id,
          'patient_medical_record_number',
          this.patient.medical_record_number
        )

        this.validMedicalRecord =
          !patientFound.id || this.patient.id === patientFound.id
      } catch (err) {
        console.log('Falha ao buscar paciente pelo prontuário', err)
      }
      if (
        !this.nextMedicalRecordNumber ||
        this.nextMedicalRecordNumber === this.patient.medical_record_number
      )
        this.getLastMedicalRecordNumber()
    },
    getLastMedicalRecordNumber() {
      this.api
        .getClinicSettings(this.clinic.id)
        .then(res => {
          this.nextMedicalRecordNumber = res.data.last_medical_record_number + 1
        })
        .catch(err => {
          console.log('Falha ao buscar próximo número de prontuário', err)
        })
    },
    searchCEP() {
      if (this.patient.address.zipCode.length === 9) {
        cep(this.patient.address.zipCode)
          .then(res => {
            this.patient.address.address = res.street
            this.patient.address.neighborhood = res.neighborhood
            this.patient.address.city = res.city
            this.patient.address.state = res.state

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    isInvalidAddress() {
      if(this.patient.address.zipCode) {
        if(!this.patient.address.neighborhood || !this.patient.address.city || !this.patient.address.state || !this.patient.address.address){
          return true
        }
      } else {
        return false
      }
    },
    async savePatient() {
      this.validatedA = false
      this.validatedN = false

      if (this.isInvalidAddress()) {
        this.validatedA = true
        this.$toast.warning('Preencha todos campos obrigatório do endereço')
      }
      if (
        this.patient.cpf &&
        this.existCpf &&
        this.existCpf.id !== this.patient.id
      ) {
        this.validatedA = true
        this.$toast.error('O CPF informado já existe em sua clínica')
      }
      if (this.patient.email && !this.validateEmail()) {
        this.validatedA = true
        this.$toast.error('O email informado deve ser valido!')
      }
      if (this.patient.cpf && !cpf.isValid(this.patient.cpf)) {
        this.validatedA = true
        this.$toast.error('O CPF informado é invalido!')
      }
      if (!this.patient.name) {
        this.validatedN = true
        this.$toast.error('Informe o nome')
      }
      if (this.validatedA || this.validatedN) {
        return
      }
      if (
        !this.patient.address.zipCode &&
        this.patient.address.address &&
        this.patient.address.city
      ) {
        this.patient.address.zipCode = '00000-000'
      }

      this.validatedA = false

      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const data = new FormData()
      this.helpers.buildFormData(data, {
        ...this.patient,
        clinic_id: clinic.id,
        type: 'PATIENT',
        medical_record_number:
          this.validMedicalRecord && this.patient.medical_record_number !== ''
            ? this.patient.medical_record_number
            : null,
        gender: this.patient.gender ? this.patient.gender.value : null,
        nationality:
          this.patient.nationality && this.patient.nationality !== 'Selecionar'
            ? this.patient.nationality.value
            : null,
        nationality_id:
          this.patient.nationality && this.patient.nationality !== 'Selecionar'
            ? this.patient.nationality.id
            : null,
        religion: this.patient.religion,
        birthday: this.patient.birthday
          ? moment(this.patient.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        ethnicity: this.patient.ethnicity ?? null,
        person_health_plans: this.patient.person_health_plans.map(
          personHealthPlan => ({
            id: personHealthPlan.id || null,
            health_plan_id: personHealthPlan.health_plan.id,
            plan_id: personHealthPlan.plan ? personHealthPlan.plan.id : null,
            registration: personHealthPlan.registration,
            token: personHealthPlan.token,
            valid_until: personHealthPlan.valid_until,
            holder: personHealthPlan.holder
          })
        )
      })

      if (this.image.cropedImage !== '') {
        data.delete('picture')
        data.append('picture', this.image.cropedBlob)
      }

      const isLoading = this.$loading.show()
      try {
        if (this.patient.id === null) {
          await this.api.createPerson(data)
          this.$toast.success('Paciente criado com sucesso!')
          return this.$router.push('/pacientes')
        } else {
          await this.api.updatePerson(this.patient.id, data)
          this.$toast.success('Paciente atualizado com sucesso!')
        }
      } catch (err) {
        this.$toast.error(err)
      } finally {
        isLoading.hide()
      }
    },
    addPersonHealthPlan(personHealthPlan) {
      this.patient.person_health_plans.push(personHealthPlan)
      this.$bvModal.hide('new-medical-insurance-modal')
    },
    editPersonHealthPlan(personHealthPlan, index) {
      this.patient.person_health_plans.splice(index, 1)
      this.$set(this.patient.person_health_plans, index, personHealthPlan)
      this.$bvModal.hide('new-medical-insurance-modal')
    },
    createHealthPlan() {
      this.editIndex = null
      this.$bvModal.show('new-medical-insurance-modal')
    },
    editHealthPlan(personHealthPlan) {
      this.personHealthPlan = { ...personHealthPlan }
      this.editIndex =
        this.patient.person_health_plans.indexOf(personHealthPlan)
      this.$bvModal.show('new-medical-insurance-modal')
    },
    async removeHealthPlan(index) {
      if (!this.patient.person_health_plans[index].id) {
        this.patient.person_health_plans.splice(index, 1)
        return
      }
      try {
        await this.api.deleteHealthPersonPlan(
          this.patient.person_health_plans[index].id
        )
        this.$toast.success('Convênio removido com sucesso')
      } catch (ex) {
        this.$toast.error(ex.message)
      }
      this.getPatient()
    },
    setReligion(value) {
      if (!value.length) return
      this.opcoesReligion.push(value)
    },
    updateImage(image) {
      this.image.cropedImage = image?.cropedImage
    },
    changeCropedImage(image) {
      this.image.cropedBlob = image
    },
    validateEmail() {
      return Boolean(
        String(this.patient.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
    },
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z0-9]/.test(event.key)) {
        event.preventDefault()
      }
    },
    checkAlphaNumeric() {
      this.patient.rg = this.patient.rg.replace(/[^a-zA-Z0-9]/g, '')
    }
  }
}
</script>

<style lang="scss">
.mb-20 {
  margin-bottom: 20px;
}

.inactive-label {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--blue-300);
}

.patient-form {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .inline-text {
    max-width: 40ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 1368px) {
      max-width: 30ch;
    }
  }

  .patient-date-input {
    margin-top: -5px !important;
  }

  .container {
    width: 100%;
    max-width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    padding: 32px;

    .main-title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 32px;
      color: var(--dark-blue);
      margin-bottom: 10px;
    }

    .sub-title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 32px;
    }

    hr {
      margin: 32px 0 56px 0;
      border-top: 1px solid var(--neutral-200);
    }

    .avatar {
      width: 100px;
      text-align: center;
      position: relative;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      svg {
        width: 100px;
        height: 100px;
      }

      .camera-button {
        width: 24px;
        height: 24px;
        background-color: var(--blue-500);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;

        .camera {
          width: 12px;
          height: 12px;
          fill: var(--neutral-000);
        }
      }
      .disabled {
        background-color: var(--neutral-100);
        cursor: not-allowed;
      }
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .delete-patient {
    color: var(--states-error) !important;
    padding: 0;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500);
  }
}
#mais-informacoes-container {
  margin-top: -20px;
}
</style>
