import LacunaWebPKI from 'web-pki'

const pki = new LacunaWebPKI(null)

export const init = (token, thumbprint) => {
  return new Promise((resolve, reject) => {
    const sign = () => {
      pki.signWithRestPki({ token, thumbprint })
        .success(resolve)
        .fail(reject)
    }
    pki.init({ ready: sign });
  })
}


export const readCertificate = (thumbprint) => {
  return new Promise((resolve, reject) => {
    pki.readCertificate(thumbprint)
      .success(resolve)
      .fail(reject)
  });
}


export const preAuthorizeSignatures = (thumbprint, documentsCount) => {
  return new Promise((resolve, reject) => {
    pki.preauthorizeSignatures({
      certificateThumbprint: thumbprint,
      signatureCount: documentsCount,
    })
    .success(resolve)
    .fail(reject);
    // .success(startBatch);
  });
}
