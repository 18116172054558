
export const getToSign = (attendanceSignatureItemCopy, medicines, medicinesAntimicrobianos) => {
  const toSign = [0];
  if (attendanceSignatureItemCopy.atestado) {
    toSign.push(1)
  }
  if (attendanceSignatureItemCopy.solicitacaoDeExames) {
    toSign.push(2)
  }
  if (attendanceSignatureItemCopy.solicitacaoDeAPA) {
    toSign.push(3)
  }
  if (attendanceSignatureItemCopy.encaminhamento) {
    toSign.push(4)
  }
  if (attendanceSignatureItemCopy.prescricaoMedicamentosa && (medicines.oral.length > 0 || medicines.ocular.length > 0)) {
    toSign.push(5)
  }
  if (attendanceSignatureItemCopy.prescricaoMedicamentosa && (medicinesAntimicrobianos.oral.length > 0 || medicinesAntimicrobianos.ocular.length > 0)) {
    toSign.push(9)
  }
  if (attendanceSignatureItemCopy.prescricaoDeOculos) {
    toSign.push(6)
  }
  if (attendanceSignatureItemCopy.declaracaoDeComparecimento) {
    toSign.push(7)
  }
  if (attendanceSignatureItemCopy.declaracaoDeComparecimentoAcompanhante) {
    toSign.push(8)
  }
  if (attendanceSignatureItemCopy.solicitacaoDeCirurgia) {
    toSign.push(10)
  }
  if (attendanceSignatureItemCopy.laudo) {
    toSign.push(11)
  }
  if (attendanceSignatureItemCopy.mapeamentoRetina) {
    toSign.push(12)
  }
  if (attendanceSignatureItemCopy.prescricaoDeLentes) {
    toSign.push(13)
  }
  if (attendanceSignatureItemCopy.posOperatorio) {
    toSign.push(14)
  }
  if (attendanceSignatureItemCopy.pressaoIntraocular) {
    toSign.push(15)
  }
  if (attendanceSignatureItemCopy.gonioscopia) {
    toSign.push(16)
  }
  if (attendanceSignatureItemCopy.biomicroscopia) {
    toSign.push(17)
  }
  if (attendanceSignatureItemCopy.motilidadeOcularExtrinseca) {
    toSign.push(18)
  }
  if (attendanceSignatureItemCopy.curvaTensional) {
    toSign.push(19)
  }
  if (attendanceSignatureItemCopy.campoVisual) {
    toSign.push(20)
  }
  if (attendanceSignatureItemCopy.tomografiaDeCorneaGalilei) {
    toSign.push(21)
  }
  if (attendanceSignatureItemCopy.solicitacaoDeTratamento && hasSomeTreatmentActive(attendanceSignatureItemCopy.solicitacaoDeTratamento.value)) {
    toSign.push(23)
  }
  if (attendanceSignatureItemCopy.topografia) {
    toSign.push(24)
  }
  if (attendanceSignatureItemCopy.octTomografiaDeCoerenciaOptica) {
    toSign.push(25)
  }
  if (attendanceSignatureItemCopy.biometria) {
    toSign.push(26)
  }
  if (attendanceSignatureItemCopy.retinografia) {
    toSign.push(27)
  }
  if (attendanceSignatureItemCopy.paquimetria) {
    toSign.push(28)
  }
  if (attendanceSignatureItemCopy.octTomografiaDeCoerenciaOpticaNervoOptico) {
    toSign.push(29)
  }
  if (attendanceSignatureItemCopy.microscopiaEspecularDeCornea) {
    toSign.push(30)
  }
  if (attendanceSignatureItemCopy.potencialAcuidadeMacular) {
    toSign.push(31)
  }
  if (attendanceSignatureItemCopy.ceratometria) {
    toSign.push(32)
  }
  if (attendanceSignatureItemCopy.bvTriagem) {
    toSign.push(33)
  }
  if (attendanceSignatureItemCopy.bvTesteAcuidadeVisual) {
    toSign.push(34)
  }
  if (attendanceSignatureItemCopy.bvTesteIshihara) {
    toSign.push(35)
  }
  if (attendanceSignatureItemCopy.oculosExtra) {
    toSign.push(36)
  }
  if(attendanceSignatureItemCopy.pressaoIntraocularCorrigida) {
    toSign.push(37)
  }
  if(attendanceSignatureItemCopy.aberrometria) {
    toSign.push(38)
  }
  if(attendanceSignatureItemCopy.complementoGlaucoma) {
    toSign.push(39)
  }
}

const getValueByKey = (key, checkAttendanceReview) => {
  const entries = Object.entries(checkAttendanceReview)
  for (const [prop, value] of entries) {
    if (prop === key) {
      return value
    }
  }
}

export const getToPrint = (attendanceReview, medicines, medicinesAntimicrobianos) => {
  const toPrint = []
  const keysToPrint = {
    atestado: 1,
    solicitacaoDeExames: 2,
    solicitacaoDeAPA: 3,
    encaminhamento: 4,
    prescricaoDeOculos: 6,
    declaracaoDeComparecimento: 7,
    declaracaoDeComparecimentoAcompanhante: 8,
    solicitacaoDeCirurgia: 10,
    laudo: 11,
    mapeamentoRetina: 12,
    prescricaoDeLentes: 13,
    posOperatorio: 14,
    prescricaoDeOculosExtra: 36,
    aberrometria: 38,
    complementoGlaucoma: 39,
  }

  const checkAttendanceReview = Object.assign({}, attendanceReview)
  Object.keys(keysToPrint).map(key => {
    if (getValueByKey(key, checkAttendanceReview)) {
      toPrint.push(keysToPrint[key])
    }
  })

  if (
    checkAttendanceReview.prescricaoMedicamentosa &&
    (medicines.oral.length > 0 || medicines.ocular.length > 0)
  ) {
    toPrint.push(5)
  }
  if (
    checkAttendanceReview.prescricaoMedicamentosa &&
    (medicinesAntimicrobianos.oral.length > 0 ||
      medicinesAntimicrobianos.ocular.length > 0)
  ) {
    toPrint.push(9)
  }

  return toPrint;
}

export const filledModules = form => {
  const filleds = []

  if (form.atestadosMedicos.fields.atestado) {
    filleds.push(1)
  }
  if (form.solicitacaoDeExames.value.length) {
    filleds.push(2)
  }
  if (form.apaAvaliacaoreAnestesica.value.length) {
    filleds.push(3)
  }
  if (form.encaminhamento.value.length) {
    filleds.push(4)
  }
  if (form.prescricaoMedicamentosaV2.value?.some(el => !isAntimicrobial(el))) {
    filleds.push(5)
  }
  if (hasSomeField(form.oculos.fields)) {
    filleds.push(6)
  }
  if (form.atestadosMedicos.fields.declaracaoDeComparecimento) {
    filleds.push(7)
  }
  if (form.atestadosMedicos.fields.declaracaoDeComparecimentoAcompanhante) {
    filleds.push(8)
  }
  if (form.prescricaoMedicamentosaV2.value?.some(el => isAntimicrobial(el))) {
    filleds.push(9)
  }
  if (form.solicitacaoDeCirurgia.value.length) {
    filleds.push(10)
  }
  if (form.laudoMedico.value.length) {
    filleds.push(11)
  }
  if (form.mapeamentoDeRetina.completed) {
    filleds.push(12)
  }
  if (hasSomeField(form.lentesDeContato.fields) && hasLenteGelatinosa(form.lentesDeContato.fields)) {
      filleds.push(13)
  }
  if (form.orientacaoPosOperatoria.value.length) {
    filleds.push(14)
  }
  if (form.pressaoIntraocular.completed) {
    if(form.pressaoIntraocular.value[0]?.olhoDireito || form.pressaoIntraocular.value[0]?.olhoEsquerdo || form.pressaoIntraocular.value[0]?.tonometria) {
      filleds.push(15)
    }
  }
  if (form.gonioscopia.completed) {
    filleds.push(16)
  }
  if (form.biomicroscopia.completed) {
    filleds.push(17)
  }
  if (form.motilidadeOcularExtrinseca.completed) {
    filleds.push(18)
  }
  if (form.curvaTensional.completed) {
    filleds.push(19)
  }
  if (form.campoVisual.completed) {
    filleds.push(20)
  }
  if (form.tomografiaDeCorneaGalilei.completed) {
    filleds.push(21)
  }
  if (hasSomeField(form.lentesDeContato.fields) && hasLenteRigida(form.lentesDeContato.fields)) {
      filleds.push(22)
  }
  if (form.solicitacaoDeTratamento.value.length && hasSomeTreatmentActive(form.solicitacaoDeTratamento.value)) {
    filleds.push(23)
  }
  if (form.topografia.completed) {
    filleds.push(24)
  }
  if (form.octTomografiaDeCoerenciaOptica.completed) {
    filleds.push(25)
  }
  if (form.biometria.completed) {
    filleds.push(26)
  }
  if (form.retinografia.completed) {
    filleds.push(27)
  }
  if (form.paquimetria.completed) {
    filleds.push(28)
  }
  if (form.octTomografiaDeCoerenciaOpticaNervoOptico.completed) {
    filleds.push(29)
  }
  if (form.microscopiaEspecularDeCornea.completed) {
    filleds.push(30)
  }
  if (form.potencialAcuidadeMacular.completed) {
    filleds.push(31)
  }
  if (form.ceratometria.completed) {
    filleds.push(32)
  }
  if (form.bvTriagem?.fields?.createdAt) {
    filleds.push(33)
  }
  if (form.bvTesteAcuidadeVisual?.fields?.bvOlhoDireito) {
    filleds.push(34)
  }
  if (form.bvTesteIshihara?.fields?.hits) {
    filleds.push(35)
  }
  if (form.oculosExtra.completed) {
    filleds.push(36)
  }
  if(form.pressaoIntraocularCorrigida.completed) {
    filleds.push(37)
  }
  if(form.aberrometria.completed) {
    filleds.push(38)
  }
  if(form.complementoGlaucoma.completed) {
    filleds.push(39)
  }

  return filleds
}
export const hasLenteGelatinosa = (fields) => {
  return fields.usarGelatinosa && Object.keys(fields).some(key => ['olhoDireitoEsferico', 'olhoDireitoCilindrico', 'olhoDireitoEixo', 'olhoDireitoDP', 'olhoDireitoAdicao', 'olhoDireitoAV',
      'olhoEsquerdoEsferico', 'olhoEsquerdoCilindrico', 'olhoEsquerdoEixo', 'olhoEsquerdoDP', 'olhoEsquerdoAdicao', 'olhoEsquerdoAV',
      'orientacao', 'usarValoresRefracao', 'marcaDaLente'].includes(key) && fields[key])
}

export const hasLenteRigida = (fields) => {
  return fields.usarRigida && Object.keys(fields).some(key => ['olhoDireitoModelo', 'olhoDireitoCurva', 'olhoDireitoDiametro', 'olhoDireitoGrau', 'olhoDireitoFabricante', 'olhoDireitoAVRigida',
    'olhoEsquerdoModelo', 'olhoEsquerdoCurva', 'olhoEsquerdoDiametro', 'olhoEsquerdoGrau', 'olhoEsquerdoFabricante', 'olhoEsquerdoAVRigida',
    'orientacaoRigida'].includes(key) && fields[key])
}

const isAntimicrobial = (item) => {
  return item.antimicrobiano || (item.medicamento?.antimicrobiano && item.medicamento?.antimicrobiano !== '0')
}

const hasSomeField = (fields) => {
  return Object.keys(fields).some(key => isNotEmptyArray(fields[key]) || isNotEmptyField(fields[key]))
}

const isNotEmptyArray = (data) => {
  return Array.isArray(data) && data.length
}

const isNotEmptyField = (field) => {
  return !Array.isArray(field) && field
}

const hasSomeTreatmentActive = (treatments) => {
  return treatments.some(treatment => treatment.active)
}

export const getDocTypes = () => {
  const typeDoc = [
    'Consulta',
    'Atestado',
    'Solicitação de Exames',
    'Solicitação de APA (Avaliação Pré Anestésica)',
    'Encaminhamento',
    'Prescrição Medicamentosa',
    'Prescrição de Óculos',
    'Declaração de Comparecimento',
    'Declaração de Comparecimento Acompanhante',
    'Prescrição Medicamentosa Antimicrobianos',
    'Solicitação de Cirurgia',
    'Laudo Médico',
    'Mapeamento de retina/fundoscopia',
    'Prescrição de lentes de contato gelatinosa',
    'Orientação ao paciente',
    'Pressão intraocular',
    'Gonioscopia',
    'Biomicroscopia',
    'Motilidade ocular extrínseca',
    'Curva tensional',
    'Campo visual',
    'Tomografia de Córnea (Galilei)',
    'Prescrição de lentes de contato rigida',
    'Solicitação de Tratamento',
    'Topografia',
    'OCT (Tomografia de Coerência Optica) de Mácula',
    'Biometria',
    'Retinografia',
    'Paquimetria',
    'OCT de Nervo Óptico',
    'Microscópia Especular de Córnea',
    'Potencial de Acuidade Macular (PAM)',
    'Ceratometria',
    'Triagem',
    'Teste Acuidade Visual',
    'Teste de Ishihara',
    'Prescrição de óculos extra',
    'Pressão intraocular corrigida',
    'Aberrometria',
    'Complemento Glaucoma'
  ]
  return typeDoc
}

export const printableDefault = (identifier) => {
  const disabledDefault = []
  return !disabledDefault.find(el => el === identifier)
}
