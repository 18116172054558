
const menuStore = {
  namespaced: true,
  state: () => ({
    opened: true,
    hiddenGroup: {
      'Anexar arquivos': false,
      'Anotações': false,
      'Anamnese oftalmológica completa': false,
      'Triagem Benefício Visão': false,
      'Exames oftalmológicos': false,
      'Exames complementares': false,
      'Informações cirúrgicas': false,
      'Diagnóstico': false,
      'Conduta': false,
    },
    groups: {
      'Anexar arquivos': [
        'anexarArquivos'
      ],
      'Anotações': [
        'informacoesAdicionaisExam'
      ],
      'Anamnese oftalmológica completa': [
        'preAnamnese',
        'queixaPrincipal',
        'anamnese',
        'historiaDaDoencaAtual',
        'antecedentesOftalmologicos',
        'antecedentesPessoais',
        'antecedentesFamiliares',
        'cirurgiasOftalmologicasAnteriores',
        'habitosDeVida',
        'medicamentosOftalmologicosEmUso',
        'outrosMedicamentosEmUso',
        'alergias',
        'informacoesAdicionaisAnamnese',
      ],
      'Triagem Benefício Visão': [
        'bvTriagem',
        'bvTesteAcuidadeVisual',
        'bvTesteIshihara',
      ],
      'Exames oftalmológicos': [
        'exameFisico',
        'ectoscopia',
        'avbplAcuidadeVisualBinocularParaLonge',
        'avbppAcuidadeVisualBinocularParaPerto',
        'avscAcuidadeVisualSemCorrecao',
        'avccAcuidadeVisualComCorrecao',
        'avppscAcuidadeVisualParaPertoSemCorrecao',
        'avppccAcuidadeVisualParaPertoComCorrecao',
        'aberrometria',
        'lensometria',
        'tonometria',
        'refracaoDinamica',
        'refracaoEstatica',
        'autorrefracaoDinamica',
        'autorrefracaoEstatica',
        'acuidadeVisualParaLongeAposRefracao',
        'acuidadeVisualParaPertoAposRefracao',
        'biomicroscopia',
        'pressaoIntraocular',
        'pressaoIntraocularCorrigida',
        'curvaTensional',
        'gonioscopia',
        'esquiascopia',
        'mapeamentoDeRetina',
        'motilidadeOcularExtrinseca',
        'coverTest',
        'reflexos',
        'informacoesAdicionais',
        'campoConfrontacao',
        'testeSensibilidadeMimicaFacial',
      ],
      'Exames complementares': [
        'biometria',
        'retinografia',
        'paquimetria',
        'campoVisual',
        'octTomografiaDeCoerenciaOptica',
        'octTomografiaDeCoerenciaOpticaNervoOptico',
        'octTomografiaDeCoerenciaOpticaSegmentoAnterior',
        'octATomografiaDeCoerenciaOptica',
        'topografia',
        'tomografiaDeCorneaPentacam',
        'tomografiaDeCorneaGalilei',
        'microscopiaEspecularDeCornea',
        'indocianinografiaIcg',
        'angiofluoresceinografiaAfg',
        'usgOcular',
        'eletrorretinogramaErgDeCampoTotal',
        'ergMultifocal',
        'potencialVisualEvocadoPve',
        'pveVarredura',
        'potencialAcuidadeMacular',
        'ceratometria',
      ],
      'Informações cirúrgicas': [
        'dadosDaCirurgia',
        'anestesia',
        'produtosUtilizados',
        'patologiaASerTratada',
        'anotacaoCirurgica',
        'intercorrencias',
      ],
      'Diagnóstico': [
        'hipoteseDiagnostica',
        'cid'
      ],
      'Conduta': [
        'solicitacaoDeExames',
        'solicitacaoDeTratamento',
        'apaAvaliacaoreAnestesica',
        'solicitacaoDeCirurgia',
        'encaminhamento',
        'prescricaoMedicamentosaV2',
        'oculos',
        'oculosExtra',
        'lentesDeContato',
        'orientacaoPosOperatoria',
        'maisInformacoes',
        'retorno',
        'atestadosMedicos',
        'laudoMedico',
        'imagensExamesEssilor',
        'complementoGlaucoma'
      ],
    },
    groupsById: {
      'Anexar arquivos': [
        'anexar-arquivos'
      ],
      'Anamnese oftalmológica completa': [
        'pre-anamnese',
        'queixa-principal',
        'anamnese',
        'historia-da-doenca-atual',
        'antecedentes-oftalmologicos',
        'antecedentes-pessoais',
        'antecedentes-familiares',
        'cirurgias-oftalmologicas-anteriores',
        'habitos-de-vida',
        'medicamentos-oftalmologicos-em-uso',
        'outros-medicamentos-em-uso',
        'alergias',
        'informacoes-adicionais-anamnese',
      ],
      'Triagem Benefício Visão': [
        'bv-triagem',
        'bv-teste-acuidade-visual',
        'bv-teste-ishihara',
      ],
      'Anotações': [
        'informacoes-adicionais-exam'
      ],
      'Exames oftalmológicos': [
        'exame-fisico',
        'ectoscopia',
        'avbpl-acuidade-visual-binocular-para-longe',
        'avbpp-acuidade-visual-binocular-para-perto',
        'avsc-acuidade-visual-sem-correcao',
        'avcc-acuidade-visual-com-correcao',
        'avppsc-acuidade-visual-para-perto-sem-correcao',
        'avppcc-acuidade-visual-para-perto-com-correcao',
        'aberrometria',
        'lensometria',
        'tonometria',
        'refracao-dinamica',
        'refracao-estatica',
        'autorrefracao-din',
        'autorrefracao-est',
        'acuidade-visual-para-longe-apos-refracao',
        'acuidade-visual-para-perto-apos-refracao',
        'biomicroscopia',
        'pressao-intraocular',
        'pressao-intraocular-corrigida',
        'curva-tensional',
        'gonioscopia',
        'esquiascopia',
        'mapeamento-de-retina',
        'motilidade-ocular-extrinseca',
        'cover-test',
        'reflexos',
        'informacoes-adicionais',
        'campo-confrontacao',
        'teste-sensibilidade-mimica-facial'
      ],
      'Exames complementares': [
        'biometria',
        'retinografia',
        'paquimetria',
        'campo-visual',
        'oct-tomografia-de-coerencia-optica-macula',
        'oct-tomografia-de-coerencia-optica-nervo-optico',
        'oct-tomografia-de-coerencia-optica-segmento-anterior',
        'oct-a-tomografia-de-coerencia-optica',
        'topografia',
        'tomografia-de-cornea-pentacam',
        'tomografia-de-cornea-galilei',
        'microscopia-especular-de-cornea',
        'indocianinografia-icg',
        'angiofluoresceinografia-afg',
        'usg-ocular',
        'eletrorretinograma-erg-de-campo-total',
        'erg-multifocal',
        'potencial-visual-evocado-pve',
        'pve-varredura',
        'potencial-acuidade-macular',
        'ceratometria',
      ],
      'Informações cirúrgicas': [
        'dados-da-cirurgia',
        'anestesia',
        'produtos-utilizados',
        'patologia-a-ser-tratada',
        'anotacao-cirurgica',
        'intercorrencias',
      ],
      'Diagnóstico': [
        'hipotese-diagnostica',
        'cid'
      ],
      'Conduta': [
        'solicitacao-de-exames',
        'solicitacao-de-tratamento',
        'apa-avaliacao-pre-anestesica',
        'solicitacao-de-cirurgia',
        'encaminhamento',
        'prescricao-medicamentosa-v2',
        'oculos',
        'oculos-extra',
        'lentes-de-contato',
        'orientacao-pos-operatoria',
        'mais-Informacoes',
        'retorno',
        'atestados-medicos',
        'laudo-medico',
        'imagens-exames-essilor',
        'complemento-glaucoma'
      ],
    },
  }),
  mutations: {
    handle(state) {
      state.opened = !state.opened
    },
    addAdditionalExam(state, formName) {
      state.groups['Exames complementares'].push(formName)
      state.groupsById['Exames complementares'].push(formName)
    },
    addTriagem(state) {
      state.groups['Triagem Benefício Visão'] = [
        'bvTriagem',
        'bvTesteAcuidadeVisual',
        'bvTesteIshihara',
      ]
    },
    removeAdditionalExamMenu(state, formName) {
      state.groups['Exames complementares'] = state.groups['Exames complementares'].filter(name =>  name !== formName)
    },
    removeTriagemMenu(state) {
      state.groups['Triagem Benefício Visão'] = []
    },
    handleGroupVisibility(state, groupName) {
      state.hiddenGroup[groupName] = !state.hiddenGroup[groupName]
    },
    clearHiddenGroup(state) {
      Object.keys(state.hiddenGroup).forEach(groupName => {state.hiddenGroup[groupName] = false}) 
    },
  },
  getters: {
    showForm: (state) => formId => {
      const formName = Object.keys(state.groupsById).find(groupName => state.groupsById[groupName].includes(formId)) 
      return state.hiddenGroup[formName]
    }
  },
  actions: {
    handleMenuVisibility(context) {
      !context.rootState.attendance.menu.opened &&
        context.rootState.attendance.history.opened &&
          context.dispatch('attendance/history/handleHistoryVisibility', null, { root: true })
      context.commit('handle')
    },
    addAdditionalExamMenu(context, formName) {
      context.commit('addAdditionalExam', formName)
    },
    addTriagemMenu(context) {
      context.commit('addTriagem')
    },
    removeAdditionalExamMenu(context, formName) {
      context.commit('removeAdditionalExamMenu', formName)
    },
    removeTriagemMenu(context, formName) {
      context.commit('removeTriagemMenu', formName)
    },
    handleGroupVisibility(context, groupName) {
      context.commit('handleGroupVisibility', groupName)
    },
    clearHiddenGroup(context) {
      context.commit('clearHiddenGroup')
    }
  },
}

export default menuStore