<template>
  <div v-can="'Laudo'" v-if="medicalReportFile" id="container">
    <div class="header-container">
      <div class="breadcrumbs">
        <router-link
          to="/laudos-medicos/laudos-pendentes"
          tag="span"
          class="link"
        >
          Laudo médico
        </router-link>
        <ChevronRight class="chevron" />
        <span v-if="medicalReportFile.file !== null">
          {{ medicalReportFile.patient.name }}
        </span>
      </div>
      <div class="header-btn-container">
        <b-button
          v-can="'LaudoMedExam4'"
          variant="link"
          style="color: var(--states-error)"
          v-if="!canEdit && !editingFinished"
          @click="deleteReport"
        >Excluir laudo
        </b-button
        >
        <b-button
          v-can="'LaudoMedExam3'"
          variant="outline-primary"
          @click="returnMedicalReportToPending"
        >Redefinir laudo
        </b-button
        >
        <b-button
          v-can="'LaudoMedExam3'"
          variant="primary"
          v-if="!canEdit && !editingFinished"
          @click="editMedicalReport"
        >Editar laudo
        </b-button
        >
      </div>
    </div>
    <div class="medical-report-container">
      <div class="report-header">
        <div class="patient-info">
          <Avatar size="64" :src="medicalReportFile.patient.picture" />
          <div class="patient-data">
            <h4>{{ medicalReportFile.patient.name }}</h4>
            <span
            >Nº do prontuário:
              {{ medicalReportFile.patient.medical_record_number }}</span
            >
            <span
            >Data de nascimento:
              {{
                new Date(
                  medicalReportFile.patient.birthday
                ).toLocaleDateString()
              }}</span
            >
          </div>
        </div>
        <h3>{{ medicalReportFile.item.name }}</h3>
        <div class="annotations">
          <ChatIcon
            v-if="isLabpacsItem"
            @click="() => $bvModal.show('annotation-modal')"
            style="cursor: pointer"
          
          />
        </div>

      </div>
      <div class="medical-report">
        <span class="attach-date">Exame anexado em {{new Date(getCreatedAtMedicalReport()).toLocaleDateString()}}</span>
        <div v-if="!isLabpacsItem">
          <div v-if="urls.length" class="img-height">
            <iframe
              v-if="isPdf"
              :src="urls[0]"
              width="100%"
              height="100%"
              style="border: none !important"
            />
            <ImageViewer v-else :images="urls" />
          </div>
          <b-skeleton-img v-else></b-skeleton-img>
      </div>
      <div class="dicon-exam" v-if="isLabpacsItem">
          <b-button variant="outline-primary" @click="openExamDicon">Visualizar resultado Labpacs</b-button>
        </div>
        <MedicalReportEditor
          ref="medicalReportEditor"
          :path="getMedicalReportPath" 
          :editingFinished="editingFinished"
        />

        <Certificate />
        <FinishActions
          v-can="'LaudoMedExam3'"
          noCid
          hideFinishLater
          :disabled="!form.value.length"
          @onFinishAttendance="onFinishAttendance"
        />
      </div>
      <div class="mr-3 mb-4 save-btn">
        <b-button
          v-if="editingFinished"
          variant="primary"
          class="btn"
          @click="updateFinishedReport"
        >Salvar edição
        </b-button
        >
      </div>
    </div>

    <AnnotationModal
      :labpacsItem="medicalReportFile.labpacs_item_reports.labpacs_item"
      @close="closeAnnotationModal"
      ref="annotationModal"
      isView
      v-if="isLabpacsItem"
      />

    <AttendanceReviewModal
      noCid
      hideAttendanceOptions
      :customReviews="reviews"
      @onFinishAttendance="onFinishAttendance"
    />

    <PauseMedicalReportModal :attendance-redirect="attendanceRedirect" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/modules/exams/api'
import {
  userHasPermission,
  getCurrentClinic,
  getCurrentUser
} from '@/utils/localStorageManager'
import { mergeAllPDFs } from '@/utils/PDFHelper'

export default {
  components: {
    AttendanceReviewModal: () =>
      import('@/components/Attendance/Forms/Components/AttendanceReviewModal'),
    Avatar: () => import('@/components/General/Avatar'),
    Certificate: () =>
      import('@/components/Attendance/Forms/Components/Certificate'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    FinishActions: () =>
      import('@/components/Attendance/Forms/Components/FinishActions'),
    ImageViewer: () => import('@/components/General/ImageViewer.vue'),
    MedicalReportEditor: () =>
      import('@/components/Appointment/MedicalReport/MedicalReportEditor.vue'),
    ChatIcon: () => import('@/assets/icons/chat.svg'),
    AnnotationModal: () => import('@/components/Exam/AnnotationModal'),
    PauseMedicalReportModal: () => import('@/views/MedicalReports/components/PauseMedicalReportModal')
  },
  metaInfo: {
    title: 'Eyecare - Laudos médicos'
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      loading: state => state.attendance.loading,
      form: state => state.attendance.form.laudoMedico
    }),
    isPdf() {
      return this.documents.some(doc => doc.path.includes('.pdf'))
    },
    isLabpacsItem() {
      return this.medicalReportFile?.labpacs_item_reports
    },
    getMedicalReportPath() {
      return this.medicalReportFile?.file?.path || this.medicalReportFile.labpacs_item_reports.labpacs_item.url
    },
  },
  async mounted() {
    this.getAttendance({
      clinicId: this.clinic.id,
      patientId: this.patientId,
      attendanceId: this.attendanceId
    })
    await this.getMedicalReportFile()
    if (this.$route.query.editing) {
      this.editingFinished = true
      this.$refs.medicalReportEditor.$el.scrollIntoView({ behavior: 'smooth' })
    }
  },
  data() {
    return {
      attendanceId: this.$route.params.attendanceId,
      patientId: this.$route.params.patientId,
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      fields: this.getDefaultFields(),
      reviews: [
        {
          label: 'Laudo médico',
          identifier: 11,
          toPrint: false,
          toSign: false,
          scopes: ['MEDICAL_REPORT']
        }
      ],
      medicalReportFile: null,
      temporaryUrl: null,
      finishOptions: {
        print: {
          medicalReport: false,
          image: false
        },

        sign: {
          medicalReport: false
        }
      },
      loaded: false,
      urls: [],
      editingFinished: false,
      attendanceRedirect: null,
      attendanceResetted: false
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.attendanceRedirect = to.path
    if (this.canEdit && this.attendance.type === 'MEDICAL_REPORT' && !this.attendanceResetted) {
      next(false)
      return this.$bvModal.show('pause-modal')
    } else {
      next()
    }
  },
  methods: {
    userHasPermission,
    ...mapActions('attendance', [
      'getAttendance',
      'finishAttendance',
      'populateMedicalRecords'
    ]),
    ...mapActions('attendance/form', ['updateMedicalRecord']),
    ...mapActions('attendance/form/laudoMedico', ['handleProps']),
    getDefaultFields() {
      return {
        laudo: null,
        descricao: null,
        professional_id: null
      }
    },
    async updateFinishedReport() {
      const isLoading = this.$loading.show()
      try {
        if (!this.form.value.length) {
          this.$toast.warning('Adicione um laudo antes de salvar')
          return
        }
        await this.updateMedicalRecord({
          ...this.form,
          editingFinishedReport: this.editingFinished
        })
        await this.api.createOrUpdateMedicalReportFile(
          this.medicalReportFile.file_id,
          this.medicalReportFile.item_id,
          'FINISHED',
          null,
          this.$route.params.attendanceId,
          this.medicalReportFile?.id

        )
        this.$toast.success('Laudo editado com sucesso!')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
        this.$router.push('/laudos-medicos/exames-laudados')
      }
    },
    async onFinishAttendance() {
      await this.api.createOrUpdateMedicalReportFile(
        this.medicalReportFile.file_id,
        this.medicalReportFile.item_id,
        'FINISHED',
        null,
        this.$route.params.attendanceId,
        this.medicalReportFile?.id

      )
      this.$router.push('/laudos-medicos/laudos-pendentes')
    },
    editMedicalReport() {
      this.editingFinished = true
      this.$refs.medicalReportEditor.$el.scrollIntoView({ behavior: 'smooth' })
    },
    async getMedicalReportFile() {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getMedicalReportFile(
          this.$route.params.medicalReportId
        )
        this.medicalReportFile = res.data
        this.medicalReportFile.patient = res.data.file?.patient || res.data.labpacs_item_reports.patient
        this.documents = this.medicalReportFile?.file_group
        await this.showDocuments(this.documents)
        if (res.data.labpacs_item_reports) {
          await this.getDiconUrl(res.data.labpacs_item_reports.labpacs_item.id)
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    async showDocuments(documents) {
      try {
        if (documents) {

          if (documents.some(doc => doc.path.includes('.pdf'))) {
            const url = await mergeAllPDFs(documents)
            this.urls.push(url)
          } else {
            documents.forEach(async doc => {
              await this.getDocTemporaryUrl(doc.path)
            })
          }
        }
      } catch (error) {
        this.$toat.error(error)
      }
    },
    async getDocTemporaryUrl(path) {
      const { data } = await this.api.getDocTemporaryUrl(path)
      this.urls.push(data)
    },
    async returnMedicalReportToPending() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          O exame laudado ficará listado na aba "Laudos pendentes" disponível para o(s) profissional(ais) laudador(es) atribuídos inicialmente
          <br/><br/>
          Essa ação não poderá ser desfeita. Deseja realmente redefinir este laudo?
          <br/>
          <span style="color: #8696AC;">As informações do laudo médico serão perdidas.</span>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          const isLoading = this.$loading.show()
          try {
            await this.api.cancelAttendance(this.$route.params.attendanceId)
            await this.api.createOrUpdateMedicalReportFile(
              this.medicalReportFile.file_id,
              this.medicalReportFile.item_id,
              'PENDING',
              null,
              null,
              this.medicalReportFile.id
            )
          } catch (error) {
            this.$toast.error(this.$toast.error(error.message))
          } finally {
            isLoading.hide()
            this.attendanceResetted = true
            this.$router.push('/laudos-medicos/laudos-pendentes')
            this.$toast.success(
              'Exame retornado para a lista de laudos pendentes'
            )
          }
        }
      })
    },
    async deleteReport() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Essa ação não poderá ser desfeita. Deseja realmente excluir este laudo?
          <br/>
          <span style="color: #8696AC;">As informações do laudo médico serão perdidas.</span>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteMedicalReport()
        }
      })
    },
    async deleteMedicalReport() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteMedicalReportFile(
          this.$route.params.medicalReportId
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.$router.push('/laudos-medicos/laudos-pendentes')
        this.$toast.success('Laudo excluído com sucesso!')
      }
    },
    getCreatedAtMedicalReport() {
      return this.medicalReportFile?.file?.created_at || this.medicalReportFile.labpacs_item_reports.created_at
    },

    async getDiconUrl(labpacsItemId) {

      const { data } = await api.getUrlDicon(labpacsItemId)
      this.medicalReportFile = {
        ...this.medicalReportFile,
        labpacs_item_reports: {
          ...this.medicalReportFile.labpacs_item_reports,
          labpacs_item: {
            ...this.medicalReportFile.labpacs_item_reports.labpacs_item,
            url: data.url
          }
        }
      }
    },
    closeAnnotationModal() {
      this.$bvModal.hide('annotation-modal')
    },
    openExamDicon() {
      window.open(this.medicalReportFile.labpacs_item_reports.labpacs_item.url, '_blank')
    }
  },
  watch: {
    attendance() {
      this.attendance && this.populateMedicalRecords()
    },
    medicalReport: function(newValue) {
      this.medicalReport = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
#container {
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .header-container {
    display: flex;
    flex-direction: row;

    .header-btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin: 15px 0;

      button {
        margin-left: 20px;
      }
    }
  }

  .breadcrumbs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .medical-report-container {
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--neutral-000);

    .report-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 0px 25px;
      border-bottom: 1px solid var(--neutral-200);

      & > div {
        padding: 10px 5px;
      }

      h3 {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 60ch;
        margin-left: 20px;
        margin-bottom: 0;
        text-align: start;
        font-weight: 700;
        font-size: 28px;
        line-height: 126%;
        letter-spacing: -0.01em;
        color: #0c1d59;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .patient-info {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-right: 1px solid var(--neutral-200);

        .patient-data {
          display: flex;
          flex-direction: column;
          text-align: start;

          h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 134%;
            margin-bottom: 0 !important;
            color: #0c1d59;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #8696ac;
          }
        }

        .patient-avatar {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .medical-report {
      display: flex;
      flex-direction: column;
      padding: 20px 50px;

      .attach-date {
        width: 100%;
        text-align: start;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #525c7a;
      }

      & .img-height {
        width: 100%;
        height: 900px;
      }

      & > div {
        margin: 5px 0;
        padding: 10px 0;

        h4 {
          width: 100%;
          text-align: start;
          font-weight: 600;
          font-size: 18px;
          line-height: 132%;
          color: #0c1d59;
        }
      }

      .signature-container {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        padding: 20px;

        span {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: start;
        }
      }

      & .active {
        background-color: #dbfff0;
      }

      & .inactive {
        background-color: #fee8e6;
      }

      .btn-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;

        & > button {
          font-weight: 700;
          font-size: 16px;
          width: 179px;
          height: 56px;
          margin-left: 30px;
          border-radius: 8px;
        }

        .disabled-btn {
          color: #00c773;
          background-color: #fff;
          border: 2px solid #00c773;

          &:hover {
            color: #00c773;
            background-color: #fff;
          }
        }
      }
    }
  }
}

.save-btn {
  display: flex;
  justify-content: end;
}

.modal-text {
  color: var(--type-active);
  font-weight: 400;
  font-size: 18px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

.dicon-exam {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    border: none;
    padding: 10px 15px;
}
.annotations {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
</style>
