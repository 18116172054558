const moduleRoutes = {
    path: '/faturamento-sus',
    component: () => import('@/views/Home'),
    children: [
      {
        path: '',
        component: () => import('./views/Home'),
        children: [
          { path: 'dashboard', component: () => import('./views/Dashboard') },
          { path: 'faturas', component: () => import('./views/Invoice') },
          { path: 'lotes-a-enviar', component: () => import('./views/LotsToSend') },
          { path: 'lotes-enviados', component: () => import('./views/SentLots') },
          { path: 'lotes-finalizados', component: () => import('./views/FinishedLots') },
          { path: 'materiais-e-medicamentos', component: () => import('./views/MatMed') },
          { path: 'relatorio-de-guias-bpa', component: () => import('./views/GuidesReportBpa') },
          { path: 'relatorio-de-guias-apac', component: () => import('./views/GuidesReportApac') },
          { path: 'configuracoes', component: () => import('./views/SusSettings') },
        ],
      },
      {
        path: ':batchId/gerenciar-guias',
        name: 'sus-manage-guides',
        component: () => import('./views/ManageGuides')
      },
      {
        path: 'batch/:batchId/lotes-enviados/:guideId',
        name: 'sus-manage-guide-items',
        component: () => import('./views/ManageGuideItems')
      },
    ]
  }


export default router => {
  router.addRoute(moduleRoutes)
}
