const moduleRoute = {
    path: '/financeiro',
    component: () => import('@/views/Home.vue'),
    children: [
        { 
            path: '', 
            component: () => import('./views/Home.vue'),
            children: [
                { path: '', component: () => import('./views/Dashboard.vue') },
                { path: 'dashboard', component: () => import('./views/Dashboard.vue') },
                { path: 'fluxo_de_caixa', component: () => import('./views/Cashflow.vue') },
                { path: 'contas_a_receber', component: () => import('./views/BillsToReceive.vue') },
                { path: 'contas_a_pagar', component: () => import('./views/BillsToPay.vue') },
                { path: 'movimentacoes', component: () => import('./views/Movements.vue') },
                { path: 'transacoes', component: () => import('./views/Transactions.vue') },
                { path: 'favorecidos', component: () => import('./views/Beneficiaries.vue') },
                { path: 'credito_paciente', component: () => import('./views/PatientCredit.vue') },
                { path: 'configuracoes', component: () => import('./views/Configurations.vue') },
            ]
        },
    ]
}

export default router => { 
    router.addRoute(moduleRoute)
}