import { userHasPermission} from '@/utils/localStorageManager';
/* ---------------- user menu -------------- */

export const showDigitalSignature = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic && [
    'DOCTOR_MANAGER',
    'DOCTOR',
  ].includes(clinic.role);
}

export const showPersonalData = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic && [
    'MANAGER',
    'DOCTOR_MANAGER',
    'DOCTOR',
  ].includes(clinic.role);
}

export const showAttendanceConfig = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic && [
    'DOCTOR_MANAGER',
    'DOCTOR'
  ].includes(clinic.role);
}


/* ---------- side bar ------------- */

export const showDoctorOverview = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic && [
    'DOCTOR_MANAGER',
    'DOCTOR',
  ].includes(clinic.role);
}

export const showAcademy = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  // remove clinicId validation to show this features for all doctors
  return clinic;
}

export const showInvoicingTiss = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === 'Faturamento TISS')
}

export const showInvoicingSus = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === 'Faturamento SUS')
}

export const showCallPanel = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === 'Painel de chamadas')
}

export const showPublicSchedule = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === 'Agendamento online')
}

export const showOnlending = () => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === 'Repasse medico');
}

export const showFeature = (feature) => {
  const clinic = JSON.parse(localStorage.getItem('clinic'))
  return clinic?.clinic_features.some(cf => cf.feature.name === feature)
}

export const showFinancial = () => {
  const perm = ['FiConRec1','FiConRec2','FiConRec3','FiConRec4','FiConRec5',]
  const hasPermition = perm.find(key => userHasPermission(key))
  return hasPermition
}

export const showFinancialButton = () => {
  const perm = ['FiConRec2','FiConRec6']
  const hasPermition = perm.find(key => userHasPermission(key))
  return hasPermition
}

export const showFinancialButtonSidebar = () => {
  const perm = ['FiConFlag2','FiConFlag1','FiConFlag5','FiConFlag4','FiConFlag3',
    'FiConCat3','FiConCat5','FiConCat1','FiConCat4','FiConCat2',
    'FiConCoBan5','FiConCoBan3','FiConCoBan1','FiConCoBan2','FiConCoBan4',
    'FiConMaq1','FiConMaq4','FiConMaq3','FiConMaq5','FiConMaq2',
    'FiConPref1','FiConPref2',
    'FiConMetPag2','FiConMetPag5','FiConMetPag1','FiConMetPag4','FiConMetPag3',
    'FiConPag4','FiConPag2','FiConPag3','FiConPag5','FiConPag1',
    'FiConRec5','FiConRec3','FiConRec4','FiConRec2','FiConRec1',
    'FiConPlan3','FiConPlan1','FiConPlan2','FiConPlan4',
    'FiDash1',
    'FiFlu1','FiMov3','FiMov1','FiMov2'
  ]
  const hasPermition = perm.find(key => userHasPermission(key))
  return hasPermition
}

export const canCreateAppointmentTypeFitting = () => {
  return userHasPermission('FpAgen9')
}

export const hasPermissionPatientCreditCategory = () => {
  return userHasPermission('FiConCred2')
}

export const hasPermissionLabpacsExams = () => {
  return userHasPermission('LaudoMedLab1')
}
